import apiClient from "../../../services/axios/axios";
import store from "../../../store/index";
import { setFilteredObject } from "../../../helpers/objectFilters";

export async function login(data) {
  try {
    //construct and get data from endpoi nt
    const endpoint = "/login";
    const res = await apiClient.post(endpoint, data);
    console.log(res);

    if (res.status == 200) {
      //create user object with desired properties
      const user = setFilteredObject(
        res.data,
        "id",
        "first_name",
        "last_name",
        "email",
        "user_pic"
      );
      // console.log(user);
      await store.dispatch("user", user);
      await store.dispatch("token", res.data.token);

      console.log("STORE_USER", store.state.user);
    }

    return res.status;
  } catch (err) {
    console.log("Err on AuthJs");
  }
}

export async function register(data) {
  try {
    //construct and get data from endpoi nt
    const endpoint = "/register";
    const res = await apiClient.post(endpoint, data);
    console.log(res);

    return res;
  } catch (err) {
    console.log("Err on AuthJs");
  }
}

export async function logout() {
  try {
    const endpoint = "/logout";
    const res = await apiClient.post(endpoint);

    return res.status;
  } catch (err) {
    console.log("Err on AuthJs");
  }
}
