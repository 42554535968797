//receives an object and returns a new one with only the keys that were passed as arguments
//if key doesnt exist it will create a new key with an empty string
export const setFilteredObject = (objToFilter, ...keys) => {
  return keys.reduce((newObj, keyName) => {
    objToFilter.hasOwnProperty(keyName)
      ? (newObj[keyName] = objToFilter[keyName])
      : (newObj[keyName] = "");

    return newObj;
  }, {});
};

//sets new properties to an existing object
//receives an object and compares keys with another object, if the key doesnt exist on the second it will create a new key with the value from the first object
export const setNewProperties = (objToCheck, objToCompare) => {
  return Object.keys(objToCheck).reduce((newObj, keyName) => {
    objToCompare.hasOwnProperty(keyName)
      ? (newObj[keyName] = objToCompare[keyName])
      : (newObj[keyName] = objToFilter[keyName]);

    return newObj;
  }, {});
};
