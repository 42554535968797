import apiClient from "../../axios/axios";
import store from "../../../store/index";
import { objectFilters } from "../../../helpers/objectFilters";

export async function getProjects() {
  try {
    //construct and get data from endpoi nt
    const endpoint = "/list_projects";
    const res = await apiClient.get(endpoint);

    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on projectJs");
  }
}

export async function getProject(id) {
  try {
    const endpoint = `/list_units/${id}`;
    const res = await apiClient.get(endpoint);

    return res;
  } catch (error) {
    console.log("Err on projectJs");
  }
}
