import axios from "axios";
import store from "../../store/index";
import router from "../../router/index";

// axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api`;

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  store.dispatch("setLoading", true);

  if (store.getters.token) {
    config.headers["Authorization"] = "Bearer " + store.getters.token;
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    store.dispatch("setLoading", false);

    return response;
  },
  (error) => {
    store.dispatch("setLoading", false);
    console.log(error);
    if (
      (error.response.status >= 500 && error.response.status < 600) ||
      error.response.status === 0
    ) {
      console.log("ERR_NETWORK DETECTED");
      router.push({ name: "ServerError", params: { err: 500 } });
    }

    return error.response;
  }
);

export default apiClient;
